import OrderIndex from './Order.vue'
import OrderShow from './Show.vue'


export default [
    {
        name: 'OrderIndex',
        component: OrderIndex,
        path: '/order'
    },
    {
        name: 'OrderShow',
        component: OrderShow,
        path: '/order/:id'
    }
]